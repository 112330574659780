import {FormEvent, useState} from "react";
import {useNavigate} from "react-router-dom";
import Swal from "sweetalert2";
import styles from "../styles/LoginScreen.module.css"
import Login from "../repositories/Login";

export function LoginScreen() {
    let model: Login = new Login();
    let shopping = require('../assets/shopping.jpg')
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const navigate = useNavigate()

    function changeEmail(event: any): any {
        setEmail(event.target.value)
    }

    function changePassword(event: any): any {
        setPassword(event.target.value)
    }

    function displayConnectionError(errorText: String) {
        Swal.fire({
            icon: "error",
            title: "Veuillez réessayer",
            html: errorText
        })
        console.log(errorText)
    }

    /**
     * Cette méthode réagit à la demande de connexion d'un utilisateur.
     *
     * Elle vérifie les paramètres mot de passe et e-mail reçus depuis les champs correspondants.
     *
     * Elle affiche des erreurs si les champs sont vides ou si l'email est invalide.
     *
     * Si tout se passe bien, on renvoie la demande au gestionnaire de connexion avec
     *
     * l'email,
     * le mot de passe,
     * la méthode réagissant au succès de la connexion,
     * la méthode affichant une erreur en cas d'échec de la connexion.
     *
     * @returns rien.
     */
    const clickLogin = async (e: FormEvent) => {
        e.preventDefault();

        let error = "";

        if (email === undefined || email.length <= 0 || !email.match("^[\\w-\\.]+@([\\w-]+.)+[\\w-]{2,4}$")) {
            error += "<li>Veuillez entrer une adresse mail valide.</li>";
        }

        if (password === undefined || password.length <= 0) {
            error += "<li>Veuillez entrer votre mot de passe.</li>";
        }

        if (error === "") {
            model.handleLogin(email, password)
                .then(value => {
                    if (value) {
                        navigate("/concours")
                    } else {
                        displayConnectionError("L'adresse mail et/ou le mot de passe que vous avez entrés sont incorrects.")
                    }
                })
                .catch(() => {
                    displayConnectionError("Impossible de se connecter.<br>Vérifiez l'adresse mail et le mot de passe entrés.")
                })
        } else {
            error = "<ul>" + error + "</ul>";
            displayConnectionError(error);
        }
    }
    return (
        <main className={styles.loginContainer}>
            <form className={styles.loginForm} onSubmit={(e) => clickLogin(e)}>
                <h1 className={styles.title}>Bon retour parmi nous&nbsp;!</h1>
                <h2 className={styles.sousTitle}>Veuillez entrer vos informations de connexion</h2>
                <label className={styles.emailInput} htmlFor="email">Email</label>
                <input type="email" id="email" className={styles.email} placeholder="Entrez votre email" onChange={changeEmail}/>
                <label className={styles.passwordInput} htmlFor="password">Mot de passe</label>
                <input type="password" id="password" className={styles.password} placeholder="Entrez votre mot de passe" onChange={changePassword}/>
                <button type="submit" id="loginBtn" className={styles.loginBtn}>Se connecter</button>
            </form>
            <img className={styles.image} src={shopping} alt={"shopping"}/>
        </main>
    )
}
