import {database} from "../firebase";
import {deleteDoc, doc, getFirestore, updateDoc} from "firebase/firestore";

export default class FireBaseRepository {

    public async getConcours() {
        const res = database.collection('concours');
        const data = await res.get();
        const bd = data.docs.map(doc => doc.data());
        return bd;
    }

    public async getConcourById(id: string) {

        const res = database.collection('concours').doc(id);
        const data = await res.get();
        const con = data.data();

        return con;
    }

    public async deleteConcours(id: string) {
        const res = getFirestore();
        const docRef = doc(res, "concours", id);
        if (docRef == null) throw new Error("Error");
        await deleteDoc(docRef)
            .then(() => {
                console.log("Entire Document has been deleted successfully.")
            })
            .catch(error => {
                console.log(error);
                throw error;
            })
    }

    public async publishContest(id: string) {
        const res = getFirestore();
        const docRef = doc(res, "concours", id);

        await updateDoc(docRef, {"estPublier": true})
            .then(() => {
                console.log("Document updated.")
            })
            .catch(error => {
                console.log(error);
            });
    }

    public async unpublishContest(id: string) {
        const res = getFirestore();
        const docRef = doc(res, "concours", id);

        await updateDoc(docRef, {"estPublier": false})
            .then(() => {
                console.log("Document updated.")
            })
            .catch(error => {
                console.log(error);
            });
    }

    public async publishMessage(id: string) {
        const res = getFirestore();
        const docRef = doc(res, "message", id);

        await updateDoc(docRef, {"estPublier": true})
            .then(() => {
                console.log("Document updated.")
            })
            .catch(error => {
                console.log(error);
            });
    }

    public async unpublishMessage(id: string) {
        const res = getFirestore();
        const docRef = doc(res, "message", id);

        await updateDoc(docRef, {"estPublier": false})
            .then(() => {
                console.log("Document updated.")
            })
            .catch(error => {
                console.log(error);
            });
    }

    public async getMessage() {

        const res = database.collection('message');
        const data = await res.get();

        const bd = data.docs.map(doc => doc.data());

        return bd;
    }

    public async getMessageById(id: string) {

        const res = database.collection('message').doc(id);
        const data = await res.get();
        const mes = data.data();

        return mes;
    }

    public async deleteMessage(id: string) {
        const res = getFirestore();
        const docRef = doc(res, "message", id);

        await deleteDoc(docRef)
            .then(() => {
                console.log("Entire Document has been deleted successfully.")
            })
            .catch(error => {
                console.log(error);
            })
    }

    public async retrieveCollection(dataName: string): Promise<any> {

        const collection = await database.collection("data").doc(dataName).get();

        const data = collection.data()

        return data!;
    }

    public async retrieveClothesFromAlgorithm(): Promise<any> {
        const collection = await database.collection("data").doc('algo').get();

        const data = JSON.parse(collection.data()!.advice)

        let clothes: { [k: string]: any } = {};
        Object.keys(data)
            .filter(value => value !== "materials" && value !== "season")
            .forEach((key) => clothes[key] = data[key]);

        return clothes;
    }

    public async updateAlgorithmClothes(newData: any) {
        const algorithmCollection = database.collection("data").doc('algo');
        const clothesCollection = database.collection("data").doc("vêtements");

        let data = JSON.parse((await algorithmCollection.get()).data()!.advice);

        Object.keys(newData).forEach((key) => {
            data[key] = newData[key];
            newData[key] = JSON.stringify(newData[key].map((value: {
                id: any,
                Type: any,
                isEditMode: any;
            }) => ({"id": value.id, "Type": value.Type, "isEditMode": false})));
        });

        await algorithmCollection.set({advice: JSON.stringify(data)});
        await clothesCollection.set(newData);
    }

    public async retrieveSeasonsFromAlgorithm(): Promise<any[]> {
        const collection = await database.collection("data").doc('algo').get();

        const data = JSON.parse(collection.data()!.advice)["season"];

        return data;
    }

    public async updateAlgorithmSeasons(newData: any) {
        const algorithmCollection = database.collection("data").doc('algo');

        let data = JSON.parse((await algorithmCollection.get()).data()!.advice);

        data["season"] = JSON.stringify(newData);

        await algorithmCollection.set({advice: JSON.stringify(data)});
    }

    public async retrieveMaterialsFromAlgorithm(): Promise<any[]> {
        const collection = await database.collection("data").doc('algo').get();

        const data = JSON.parse(collection.data()!.advice)["materials"];

        return data;
    }

    public async updateAlgorithmMaterials(newData: any[]) {
        const algorithmCollection = database.collection("data").doc('algo');
        const materialsCollection = database.collection("data").doc("matériaux");

        let data = JSON.parse((await algorithmCollection.get()).data()!.advice);

        data["materials"] = newData;

        let materialsData = newData.map((value: { id: any, Name: any, isEditMode: any; }) => ({
            "id": value.id,
            "nom": value.Name,
            "isEditMode": false
        }));

        await algorithmCollection.set({advice: JSON.stringify(data)});
        await materialsCollection.set({matériaux: JSON.stringify(materialsData)});
    }

    /*public async updateAlgo(dataName:string, newData:any){
        const collection = database.collection("data").doc(dataName);

        await collection.set({advice: JSON.stringify(newData)});
    }

    public async updateClothes(dataName:string, newData:any){
        const collection = database.collection("data").doc(dataName);

        await collection.set(newData);
    }*/

    public async retrieveConfigurationData(dataName: string): Promise<any[]> {
        const collection = await database.collection("data").doc(dataName).get();

        const data = JSON.parse(collection.data()![dataName]);

        return data;
    }

    public async updateConfigurationData(dataName: string, variableName: string, newData: any[]) {
        const collection = database.collection("data").doc(dataName);

        await collection.set({[variableName]: JSON.stringify(newData)});
    }

    public async modifyAlgorithm(data: {}) {

        const res = database.collection('data').doc("algo2")

        await res.set({advice: JSON.stringify(data)});
    }

    public async retrieveAlgorithm() {

        const res = database.collection('data').doc("algo2");
        const data = await res.get();

        return data.data()!.advice;
    }
}