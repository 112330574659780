import React, {useState} from 'react';
import {Container, Nav, Navbar} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import {observer} from 'mobx-react-lite';
import {sessionStore} from '../stores/SessionStore';

import logo from '../assets/icon_kento.png';
import 'bootstrap/dist/css/bootstrap.min.css';
import styles from '../styles/NavigationBar.module.css';

const NavigationBar = observer(() => {
    const isAuthenticated = sessionStore.user === undefined ? false : true;

    return (
        <header className={styles.header}>
            <Navbar bg="light" expand="lg" className={styles.navbar}>
                <Container className={styles.container}>
                    <Navbar.Brand>
                        <img className={styles.logo} src={logo} alt="kento"/>
                    </Navbar.Brand>
                    {isAuthenticated && (
                        <React.Fragment>
                            <Navbar.Toggle aria-controls="basic-navbar-nav"/>
                            <Navbar.Collapse id="basic-navbar-nav">
                                <Nav className={`me-auto ${styles.navbarLinks}`}>
                                    <Nav.Link as={Link} to="/concours">
                                        Concours
                                    </Nav.Link>
                                    <Nav.Link as={Link} to="/messages">
                                        Messages
                                    </Nav.Link>
                                    <Nav.Link as={Link} to="/configuration">
                                        Configuration
                                    </Nav.Link>
                                    <Nav.Link as={Link} to="/signout">
                                        Déconnexion
                                    </Nav.Link>
                                </Nav>
                            </Navbar.Collapse>
                        </React.Fragment>
                    )}
                </Container>
            </Navbar>
        </header>
    );
});

export default NavigationBar;
