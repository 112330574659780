import {useState} from 'react';
import styles from "../../styles/MessagesScreen.module.css";
import FirebaseRepository from "../../repositories/FirebaseRepository";
import IMessage from '../../models/interfaces/IMessage';
import dayjs from "dayjs";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowUpFromBracket, faPenToSquare, faTrashCan, faXmark} from "@fortawesome/free-solid-svg-icons"
import UpdateMessageModal from "./modals/UpdateMessageModal";
import Swal from "sweetalert2";

interface Props {
    message: IMessage;
    id: Function;
    refresh: Function;
    r: Boolean;
    loading: Function;
}

export default function MessageComponents({message, refresh, r, loading}: Props) {

    const fbRepo = new FirebaseRepository();
    const [showItModif, setShowItModif] = useState<boolean>(false);

    const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    });

    /**
     * Cette méthode réagit à la demande de supprimer d'un message de la part d'un utilisateur.
     *
     * Elle supprime les messages en BD.
     *
     * Elle refresh la page.
     *
     * Elle active le reload
     *
     * Si tout se passe bien, on ajout le message en BD et on remet les champs a vide avec
     *
     */
    const onDeleted = async () => {
        await fbRepo.deleteMessage(message.id)
            .then(() => {
                Toast.fire({
                    icon: "success",
                    title: "Le message a été supprimé avec succès."
                })
            })
            .catch((error) => {
                Toast.fire({
                    icon: "error",
                    title: "Le message n'a pas pu être supprimé. Veuillez réessayer plus tard."
                })
            });
        refresh(!r);
        loading(true)
    }


    const publish = () => {
        message.estPublier = true;
        fbRepo.publishMessage(message.id)
            .then(() => {
                Toast.fire({
                    icon: "success",
                    title: "Le message a bien été publié."
                });
            })
            .catch(() => {
                Toast.fire({
                    icon: "error",
                    title: "Le message n'a pas pu être publié. Veuillez réessayer plus tard."
                });
            }).finally(() => {
            refresh(!r);
            loading(true)
        });

    }

    const unpublish = () => {
        message.estPublier = false;
        fbRepo.unpublishMessage(message.id)
            .then(result => {
                Toast.fire({
                    icon: "success",
                    title: "Le message est de nouveau un brouillon."
                });
            })
            .catch(error => {
                Toast.fire({
                    icon: "error",
                    title: "Le message n'a pas pu être retransformé en brouillon. Veuillez réessayer plus tard."
                });
            }).finally(() => {
            refresh(!r);
            loading(true)
        });
    }

    function confirmDelete() {
        Swal.fire({
            title: "Êtes-vous sûr(e) de vouloir supprimer le message ?",
            text: "Cette action est irréversible.",
            showCancelButton: true,
            confirmButtonText: "Confirmer",
            cancelButtonText: "Annuler"
        }).then((result) => {
            if (result.isConfirmed) onDeleted();
        }).catch((error) => {
            return;
        });
    }

    /**
     * Cette méthode réagit à la demande d'une modification d'un message de la part d'un utilisateur.
     *
     * Elle affiche le dialog avec le fomulaire de mofication.
     *
     * Elle refresh la page.
     *
     * Elle active le reload
     *
     * Si tout se passe bien, on affiche la fenetre de modification on met à jour le message en BD
     *
     */
    const onModify = async () => {
        setShowItModif(true);
    }


    const getPublishTest = () => {
        return message.estPublier;
    }

    return (
        <article className={styles.message}>
            <header>
                <h2 className={styles.theme}>{message.sujet}</h2>
                <div className={styles.buttons}>
                    {
                        getPublishTest() &&
                        <button className={`${styles.primaryButton} ${styles.texted}`} onClick={unpublish}><FontAwesomeIcon icon={faXmark}/>
                            <span className={styles.buttonText}>Annuler la publication</span>
                        </button>
                    }
                    {
                        !getPublishTest() &&
                        <button className={`${styles.primaryButton} ${styles.texted}`} onClick={publish}><FontAwesomeIcon
                            icon={faArrowUpFromBracket}/>
                            <span className={styles.buttonText}>Publier</span>
                        </button>
                    }
                    <button className={styles.secondaryButton} onClick={onModify}><FontAwesomeIcon icon={faPenToSquare}/><span className={styles.buttonText}>Modifier</span>
                    </button>
                    <button className={styles.cancelButton} onClick={confirmDelete}><FontAwesomeIcon icon={faTrashCan}/><span className={styles.buttonText}>Supprimer</span>
                    </button>
                </div>
            </header>
            <p className={styles.text}>{message.texte}</p>
            <span className={styles.promoCode}><span className={styles.messageLabel}>Code promo</span> : <span
                className={message.codePromo === "" ? styles.noCode : styles.code}>{message.codePromo === "" ? "aucun" : message.codePromo}</span></span>
            <footer>
                <span className={styles.messageInfo}><span className={styles.messageLabel}>Morphologie</span> : {message.cible === "all" ? "Toutes" : message.cible.toUpperCase()}</span>
                {message.marque !== '' && <span className={styles.messageInfo}><span className={styles.messageLabel}>Marque</span> : {message.marque}</span>}
            </footer>
            <span className={styles.date}>{dayjs(message.date).format("DD/MM/YYYY")}</span>
            <div>
                <UpdateMessageModal show={setShowItModif} showIt={showItModif} refresh={refresh} r={r} loading={loading}
                                    id={message.id}/>
            </div>
        </article>

    );
}

