import Modal from "react-bootstrap/Modal";
import "bootstrap/dist/css/bootstrap.min.css";
import {useState} from "react";
import FirebaseRepository from "../../../repositories/FirebaseRepository";
import {database} from "../../../firebase";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faXmark} from "@fortawesome/free-solid-svg-icons";
import dayjs from "dayjs";
import Swal from "sweetalert2";
import styles from "../../../styles/ContestsScreen.module.css"

interface Props {
    show: Function;
    showIt: boolean;
    refresh: Function;
    r: Boolean;
    loading: Function;
    id: string;
}


export default function UpdateContestModal({showIt, show, refresh, r, loading, id}: Props) {

    const fbRepo = new FirebaseRepository();
    const [dateDebut, setDateDebut] = useState("")
    const [dateFin, setDateFin] = useState("")
    const [lienConcours, setLienConcours] = useState("");
    const [description, setDescription] = useState("");
    const [theme, setTheme] = useState("");
    const [loaded, hasLoad] = useState(false);
    const [idConcours, setId] = useState("");
    const [estPublier, setEstPublier] = useState(false);
    const toDay = dayjs(Date.now()).format("YYYY-MM-DD");

    const loadConcours = async () => {
        await fbRepo.getConcourById(id).then((value) => {
            setTheme(value!.theme);
            setDescription(value!.description);
            setDateDebut(value!.dateDebut);
            setDateFin(value!.dateFin);
            setLienConcours(value!.lienConcours);
            setEstPublier(value!.estPublier);
            setId(value!.id);
            hasLoad(true);
        });

    }

    if (showIt && !loaded) {
        loadConcours();

    }


    function changeDateDebut(event: any): any {
        setDateDebut(event.target.value)
    }

    function changeDateFin(event: any): any {
        setDateFin(event.target.value)
    }

    function changeLienConcours(event: any): any {
        setLienConcours(event.target.value)
    }

    function changeTheme(event: any): any {
        setTheme(event.target.value)
    }

    function changeDescription(event: any): any {
        setDescription(event.target.value)
    }

    const onModify = async () => {
        let error: string = "";

        if (theme.trim().length == 0) {
            error += "<li>Le thème du concours ne peut pas être vide.</li>";
        }

        if (theme.trim().length > 50) {
            error += "<li>Le thème du concours ne peut pas excéder les 50 caractères.</li>";
        }

        if (dateFin === dateDebut) {
            error += "<li>La date de fin du concours ne peut être la même que la date de début du concours.</li>";
        }

        if (theme.trim().length > 50) {
            error += "<li>Le thème du concours ne peut pas excéder les 50 caractères.</li>";
        }

        if (description.trim().length === 0) {
            error += "<li>La description du concours ne peut pas être vide.</li>";
        }

        if (description.trim().length > 500) {
            error += "<li>La description du concours ne peut pas excéder les 500 caractères.</li>";
        }

        if (dateDebut.length === 0) {
            error += "<li>La date de début du concours doit être définie.</li>";
        }

        if (dateFin.length === 0) {
            error += "<li>La date de fin du concours doit être définie.</li>";
        }

        if (error.length > 0) {
            error = "<ul style='text-align: left'>" + error + "</ul>";

            await Swal.fire({
                icon: 'error',
                title: 'Oups...',
                html: error
            })
        } else {
            const res = database.collection('concours').doc(idConcours).set({
                id: id,
                theme: theme,
                description: description,
                dateDebut: dateDebut,
                dateFin: dateFin,
                lienConcours: lienConcours,
                estPublier: estPublier
            });
            res.then(() => {
                setDateDebut("");
                setDateFin("");
                setLienConcours("");
                setTheme("");
                setDescription("");
                hideModal();
                refresh(!r);
                loading(true);

                Swal.fire({
                    icon: "success",
                    title: "Le concours a bien été modifié !",
                    text: "N'oubliez pas de le publier si vous en êtes satisfait(e) et que ce n'est pas déjà fait !"
                });
            }).catch((e: any) =>

                Swal.fire({
                    icon: 'error',
                    title: 'Oups...',
                    text: 'Une erreur est survenue lors de la modification du concours. Veuillez réessayer.'
                }));
        }
    }

    const hideModal = () => {
        show(false);
    };

    return (
        <Modal show={showIt}>
            <Modal.Header>
                <h1 className={styles.modalTitle}>Formulaire</h1>
                <button className={styles.quitModal} onClick={hideModal}><FontAwesomeIcon icon={faXmark}/></button>
            </Modal.Header>

            <Modal.Body>
                <form className={`theme-form ${styles.contestModal}`}>
                    <label htmlFor="theme">* Thème</label>
                    <input id="theme" name="theme" type="text" placeholder="Thème du concours" value={theme}
                           onChange={changeTheme} required/>

                    <label htmlFor="desc">* Description</label>
                    <textarea id="desc" name="desc" placeholder="Description du concours" value={description}
                              onChange={changeDescription} required/>

                    <label htmlFor="dateDebut">* Date de début</label>
                    <input type="date" name="dateDebut" id="dateDebut" value={dateDebut} min={toDay}
                           onChange={changeDateDebut} required/>

                    <label htmlFor="dateFin">* Date de fin</label>
                    <input type="date" name="dateFin" id="dateFin" value={dateFin} min={dateDebut}
                           onChange={changeDateFin} required/>

                    <label htmlFor="link">Lien</label>
                    <input type="text" name="link" placeholder="Lien du concours" id="link" value={lienConcours}
                           onChange={changeLienConcours}/>

                    <input type="button" id="btn-save" value="Enregistrer" onClick={onModify}/>
                </form>
            </Modal.Body>
        </Modal>
    );

}
