import {useEffect, useState} from "react";
import styles from "../styles/ConfigurationScreen.module.css"
import {IconButton} from "@mui/material";
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import DiscountIcon from '@mui/icons-material/Discount';
import Face4Icon from '@mui/icons-material/Face4';
import TextureIcon from '@mui/icons-material/Texture';
import FireBaseRepository from '../repositories/FirebaseRepository';
import ClothesConfigurationComponents from "../components/configuration/ConfigurationDataComponent";
import logo from "../assets/icon_kento.png";
import BeatLoader from "react-spinners/BeatLoader";

export function ConfigurationScreen() {

    const fbRepo = new FireBaseRepository();

    const [selected, setSelected] = useState(1);
    const [loading, setLoading] = useState(true);
    const [configurationName, setConfigurationName] = useState<string>('');
    const [configurationData, setConfigurationData] = useState<any[]>([]);
    const [configurationProperties, setConfigurationProperties] = useState<string[]>([]);
    const [configurationFiltersData, setConfigurationFiltersData] = useState<any>({});
    const [configurationFilters, setConfigurationFilters] = useState<string[]>([]);
    const [configurationDoubleFilters, setConfigurationDoubleFilters] = useState<string[]>([]);

    const menuTitles = ['vêtements', 'matériaux', 'marques', 'styles'];
    const menuIcons = [
        <AssignmentIndIcon sx={{fontSize: 30, color: 'white', '&:hover': {color: '#e0576b',}}}/>,
        <TextureIcon sx={{fontSize: 30, color: 'white', '&:hover': {color: '#e0576b',}}}/>,
        <DiscountIcon sx={{fontSize: 30, color: 'white', '&:hover': {color: '#e0576b',}}}/>,
        <Face4Icon sx={{fontSize: 30, color: 'white', '&:hover': {color: '#e0576b',}}}/>,
    ];

    useEffect(() => {
        (async function () {
            await handleChange(0);
        })();
    }, []);

    async function loadAlgorithm(configName: string) {
        let doubleFilters: string[] = [];

        await fbRepo.retrieveCollection('catégories')
            .then(value => {
                setConfigurationFiltersData(value);
                let filters = Object.keys(value).sort();
                setConfigurationFilters(filters);
                doubleFilters = JSON.parse(value[filters[0]]).sort();
                setConfigurationDoubleFilters(doubleFilters);
            });

        await fbRepo.retrieveClothesFromAlgorithm()
            .then(value => {
                Object.keys(value).forEach(k => value[k] = value[k].sort((a: {Type: string}, b: {Type: string}) => a.Type.localeCompare(b.Type)))
                setConfigurationName(configName);
                let data = value[doubleFilters[0]];
                setConfigurationData(value);
                setConfigurationProperties(Object.keys(data[0]).filter(value => value !== "id" && value !== "isEditMode"));
            });
    }

    function setAllData(configurationName: string, value: any[]) {
        setConfigurationName(configurationName);
        setConfigurationData(value);
        setConfigurationProperties(Object.keys(value[0]).filter(value => value !== "id" && value !== "isEditMode"));
        setConfigurationFiltersData({});
        setConfigurationFilters([]);
        setConfigurationDoubleFilters([]);
    }

    async function handleChange(index: number) {
        setLoading(true);
        if (index !== selected) {
            setSelected(index);
            let configName = menuTitles[index].toLowerCase();

            switch (index) {
                case 0 :
                    await loadAlgorithm(configName);
                    break;

                case 1 :
                    await fbRepo.retrieveMaterialsFromAlgorithm()
                        .then(value => {
                            value.sort((a, b) => a.Name.localeCompare(b.Name))
                            setAllData(configName, value);
                        });
                    break;

                default :
                    await fbRepo.retrieveConfigurationData(configName)
                        .then(value => {
                            value.sort((a, b) => a.nom.localeCompare(b.nom))
                            setAllData(configName, value);
                        })
            }
        }
        setLoading(false);
    }

    return (
        loading ?
            <div className="beatLoader">
                <BeatLoader
                    color={'#e0576b'}
                    loading={loading}
                    size={30}
                />
            </div>
            :
            <main className={styles.configurationPage}>
                <div className={styles.leftMenu}>

                    <div className={styles.menuTitle}>
                        <img className={styles.titleLogo} src={logo} alt="kento"/>
                        <span className={styles.menuTitleText}>KENTOLADY</span>
                    </div>

                    {menuTitles.map((title, index) => {
                        return <div className={`${styles.menuOption} ${selected === index ? styles.menuOptionSelected : ""}`}
                                    onClick={() => handleChange(index)}>

                            <IconButton sx={{backgroundColor: '#e0576b', '&:hover': {backgroundColor: 'white',}}}
                                        className={styles.menuOptionIcon}>
                                {menuIcons[index]}
                            </IconButton>

                            <p className={styles.menuOptionText}>{title}</p>

                        </div>
                    })}
                </div>

                <div className={styles.configurationMenu}>
                    <ClothesConfigurationComponents
                        pageTitle={`Configurer les ${menuTitles[selected]}`}
                        configurationName={configurationName}
                        configurationData={configurationData}
                        configurationProperties={configurationProperties}
                        configurationFiltersData={configurationFiltersData}
                        configurationFilters={configurationFilters}
                        configurationDoubleFilters={configurationDoubleFilters}
                    />
                </div>

            </main>
    )
}

