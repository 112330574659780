import {auth, database} from "../firebase";
import ILogin from "./ILogin";
import {sessionStore} from "../stores/SessionStore";

/**
 * Login gère tous les événements liés à la connexion ou déconnexion d'un utilisateur.
 */
export default class Login implements ILogin {
    /**
     * Cette méthode gère la connexion d'un utilisateur.
     *
     * Elle va demander une requête de connexion à FireBase en utilisant le mot de passe et l'email donnés.
     *
     * En cas de réussite de la connexion, elle va récupérer le profil correspondant aux informations de connexion
     * et appeler la méthode donnée en paramètre pour cet effet.
     *
     * En cas d'échec de connexion, elle va appeler la méthode donnée en paramètre pour cet effet.
     *
     * @param email : l'email entré par l'utilisateur.
     * @param password : le mot de passe entré par l'utilisateur.
     * @param callback : la méthode à appeler en cas de succès de la connexion.
     * @param errorCallback : la méthode à appeler en cas d'échec de la connexion.
     */
    public async handleLogin(
        email: string,
        password: string,
    ) {
        return auth
            .signInWithEmailAndPassword(email, password)
            .then(async (userCredentials: any) => {
                const res = database.collection("users").doc(userCredentials.user.uid);
                const data = await res.get();
                const result = await data.data();
                if (result !== undefined) {
                    sessionStore.user = {id: data.id, firstname: result.firstname, name: result.name};
                }
                return result!.role !== undefined && result!.role === "admin";
            }).catch((error) => {
                console.log(error);
            })
    }

    public async startUpLogin(callback: Function, setLoading: Function) {
        const subscribe = auth.onAuthStateChanged((user: any) => {
            if (user) {
                subscribe();
                callback();
            } else {
                subscribe();
                setLoading(false);
            }
        });
    }

    /**
     * Cette méthode gère la déconnexion d'un utilisateur.
     *
     * Elle va demander une requête de déconnexion à FireBase.
     *
     * En cas de réussite de la déconnexion, elle va appeler la méthode donnée en paramètre pour cet effet.
     *
     * @param callback : la méthode à appeler en cas de succès de la déconnexion.
     */
    public async handleLogout() {
        await auth.signOut();
        sessionStore.logout()
    }

    public hasAuthenticated() {
        return false;
    }
}
