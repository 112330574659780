import React, {useState} from 'react';
import styles from "../../styles/ContestsScreen.module.css";
import FirebaseRepository from "../../repositories/FirebaseRepository";
import IContest from '../../models/interfaces/IContest';
import dayjs from "dayjs";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {
    faArrowUpFromBracket,
    faCircleCheck,
    faClock,
    faPenRuler,
    faPenToSquare,
    faStopwatch,
    faTrashCan,
    faXmark
} from "@fortawesome/free-solid-svg-icons";
import Swal from 'sweetalert2';
import 'react-toastify/dist/ReactToastify.css';
import UpdateContestModal from "./modals/UpdateContestModal";

interface Props {
    concours: IContest;
    refresh: Function;
    r: Boolean;
    loading: Function;
}

export default function ContestComponent({concours, refresh, r, loading}: Props) {

    const fbRepo = new FirebaseRepository();
    const [showItModif, setShowItModif] = useState<boolean>(false);

    const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    });

    const onDeleted = async () => {
        await fbRepo.deleteConcours(concours.id)
            .then(() => {
                Toast.fire({
                    icon: "success",
                    title: "Le concours a été supprimé avec succès."
                })
            })
            .catch((error) => {
                Toast.fire({
                    icon: "error",
                    title: "Le concours n'a pas pu être supprimé. Veuillez réessayer plus tard."
                })
            });
        refresh(!r);
        loading(true);
    }

    function confirmDelete() {
        Swal.fire({
            title: "Êtes-vous sûr(e) de vouloir supprimer le message ?",
            text: "Cette action est irréversible.",
            showCancelButton: true,
            confirmButtonText: "Confirmer",
            cancelButtonText: "Annuler"
        }).then((result) => {
            if (result.isConfirmed) onDeleted();
        }).catch(() => {
            return;
        });
    }


    const onModify = async () => {
        setShowItModif(true);
    }

    /**
     * Calcule du temps du concours
     */
    let d1 = new Date(Date.now());
    let d2 = new Date(concours.dateFin);
    let time_diff = d2.getTime() - d1.getTime();
    let days_Diff = Math.ceil(time_diff / (1000 * 3600 * 24));
    let tooLate = days_Diff <= 0;

    const publish = () => {
        concours.estPublier = true;
        fbRepo.publishContest(concours.id)
            .then(() => {
                Toast.fire({
                    icon: "success",
                    title: "Le concours a bien été publié."
                })
            })
            .catch(() => {
                Toast.fire({
                    icon: "error",
                    title: "Le concours n'a pas pu être publié. Veuillez réessayer plus tard."
                });
            })
            .finally(() => {
                refresh(!r);
                loading(true)
            });
    }

    const unpublish = () => {
        concours.estPublier = false;
        fbRepo.unpublishContest(concours.id)
            .then(result => {
                Toast.fire({
                    icon: "success",
                    title: "Le concours est de nouveau un brouillon."
                });
            })
            .catch(error => {
                Toast.fire({
                    icon: "error",
                    title: "Le concours n'a pas pu être retransformé en brouillon. Veuillez réessayer plus tard."
                });
            })
            .finally(() => {
                refresh(!r);
                loading(true)
            });
    }

    return (
        <article className={styles.contest}>
            <header>
                <div className={styles.infos}>
                    <h2 className={styles.theme}>{concours.theme}</h2>
                    <span
                        className={`${styles.status} ${(!concours.estPublier ? styles.draft : tooLate ? styles.finished : styles.active)}`}><FontAwesomeIcon
                        icon={!concours.estPublier ? faPenRuler : tooLate ? faCircleCheck : faClock}/><span
                        className={styles.statusText}>{!concours.estPublier ? "Brouillon" : tooLate ? "Terminé" : "En cours"}</span></span>
                </div>
                <div className={styles.buttons}>
                    {
                        concours.estPublier &&
                        <button className={`${styles.primaryButton} ${styles.texted}`} onClick={unpublish}><FontAwesomeIcon className={styles.buttonIcon} icon={faXmark}/>
                            <span className={styles.buttonText}>Annuler la publication</span>
                        </button>
                    }
                    {
                        !concours.estPublier &&
                        <button className={`${styles.primaryButton} ${styles.texted}`} onClick={publish}><FontAwesomeIcon className={styles.buttonIcon}
                            icon={faArrowUpFromBracket}/>
                            <span className={styles.buttonText}>Publier</span>
                        </button>
                    }
                    <button className={styles.secondaryButton}><FontAwesomeIcon className={styles.buttonIcon} icon={faPenToSquare} onClick={onModify}/><span className={styles.buttonText}>Modifier</span>
                    </button>
                    <button className={styles.cancelButton}><FontAwesomeIcon className={styles.buttonIcon} icon={faTrashCan} onClick={confirmDelete}/><span className={styles.buttonText}>Supprimer</span>
                    </button>
                </div>
            </header>
            <p className={styles.text}>{concours.description}</p>
            <footer>
                <span className={styles.dates}><FontAwesomeIcon icon={faStopwatch}/> <span
                    className={`${styles.date} ${styles.dateDebut}`}>{dayjs(concours.dateDebut).format("DD/MM/YYYY")}</span> - <span
                    className={`${styles.date} ${styles.dateFin}`}>{dayjs(concours.dateFin).format("DD/MM/YYYY")}</span>
                    {days_Diff >= 0 &&
                        <span className={styles.remainingTimeText}> | Plus que <span className={styles.remainingTime}>{days_Diff}</span> jour(s) !</span>
                    }
                </span>
            </footer>
            <div>
                <UpdateContestModal show={setShowItModif} showIt={showItModif} refresh={refresh} r={r} loading={loading}
                                    id={concours.id}/>
            </div>
        </article>
    );
}


