import {useNavigate} from 'react-router-dom';
import Login from '../repositories/Login';

export default function SignoutScreen() {
    let model: Login = new Login();
    const navigate = useNavigate()

    model.handleLogout().then(() => {
        navigate("/")
    })
    return (
        <main>SignoutScreen</main>
    )
}
