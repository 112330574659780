// Import the functions you need from the SDKs you need
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyD1WyaCcA126k9ZpIe3I7XX8S03PTtrvyM",
    authDomain: "kentolady-12ed7.firebaseapp.com",
    databaseURL: "https://kentolady-12ed7-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "kentolady-12ed7",
    storageBucket: "kentolady-12ed7.appspot.com",
    messagingSenderId: "1057786758393",
    appId: "1:1057786758393:web:c45cf642e3261d2c794e28"
};

if (firebase.apps.length === 0) {
    firebase.initializeApp(firebaseConfig);
} else {
    firebase.app();
}

let auth = firebase.auth();
auth.setPersistence(firebase.auth.Auth.Persistence.LOCAL);
let database = firebase.firestore();


export {auth, database}