import {makeAutoObservable} from "mobx";
import {database} from "../firebase";
import User from "../models/user";

class SessionStore {
    constructor() {
        makeAutoObservable(this)
        this.loadUser()
    }

    private _user: any;

    get user() {
        return this._user
    }

    set user(data: any) {
        this._user = new User(data.firstname, data.name, data.id);
        this.saveUser(data.id);
    }

    async loadUser() {
        let userId = localStorage.getItem("userid")
        if (userId && userId !== "") {
            const res = database.collection("users").doc(userId);
            const data = await res.get();
            const result = data.data();
            this.user = {result, id: localStorage.getItem("userid")!};
        }
    }

    async saveUser(id: string) {
        localStorage.setItem("userid", id)
        //await this.loadUser();
    }

    logout() {
        this._user = undefined
        localStorage.removeItem('userid')
    }


}

export const sessionStore = new SessionStore()