export default class User {
    private firstname: string;
    private name: string;
    private uid: string;

    constructor(firstname: string, name: string, uid: string) {
        this.firstname = firstname;
        this.name = name;
        this.uid = uid;
    }
}