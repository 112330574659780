import Modal from "react-bootstrap/Modal";
import "bootstrap/dist/css/bootstrap.min.css";
import {useState} from "react";
import FirebaseRepository from "../../../repositories/FirebaseRepository";
import {database} from "../../../firebase";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faXmark} from "@fortawesome/free-solid-svg-icons";
import Swal from "sweetalert2";
import styles from "../../../styles/MessagesScreen.module.css"

interface Props {
    show: Function;
    showIt: boolean;
    id: string;
    refresh: Function;
    r: Boolean;
    loading: Function;
}


export default function UpdateMessageModal({showIt, show, id, refresh, r, loading}: Props) {

    const fbRepo = new FirebaseRepository();
    const [sujet, setSujet] = useState("");
    const [message, setMessage] = useState("");
    const [morphologie, setMorphologie] = useState("all");
    const [loaded, hasLoad] = useState(false);
    const [idMessage, setId] = useState("");
    const [codePromo, setCodePromo] = useState("");
    const [marque, setMarque] = useState("");
    const [isChecked, setIsChecked] = useState(false);
    const [inputDisabled, setInputDisabled] = useState(true);


    const loadMessage = async () => {
        await fbRepo.getMessageById(id).then((value) => {
            setSujet(value!.sujet);
            setMessage(value!.texte);
            setMorphologie(value!.cible);
            setMarque(value!.marque)
            setIsChecked(value!.marque !== "")
            setInputDisabled(value!.marque === "")
            setCodePromo(value!.codePromo);
            setId(value!.id);
            hasLoad(true);
        });

    }

    if (showIt && !loaded) {
        loadMessage();
    }


    const hideModal = () => {

        show(false);

    };


    const onModify = async () => {
        let error: string = "";

        if (sujet.trim().length === 0) {
            error += "<li>Le sujet du message ne peut pas être vide.</li>";
        }

        if (sujet.trim().length > 50) {
            error += "<li>Le sujet du message ne peut excéder les 50 caractères.</li>";
        }

        if (message.trim().length === 0) {
            error += "<li>Le message ne peut pas être vide.</li>";
        }

        if (message.length >= 500) {
            error += "<li>Le message ne peut excéder les 500 caractères.</li>";
        }

        if (marque.length >= 50 && isChecked) {
            error += "<li>Le champ 'Marque' ne peut excéder les 50 caractères.</li>";
        }

        if (marque.trim().length === 0 && isChecked) {
            error += "<li>Le champ 'Marque' ne peut pas être vide</li>";
        }

        if (error.length > 0) {
            error = "<ul>" + error + "</ul>";

            Swal.fire({
                icon: 'error',
                title: 'Oups...',
                html: error
            })
        } else {
            if (morphologie === undefined) {
                setMorphologie("all");
            }
            console.log(JSON.stringify({
                id: id,
                sujet: sujet,
                texte: message,
                cible: morphologie,
                codePromo: codePromo,
                marque: isChecked ? marque : "",
                date: Date.now(),
            }))
            const res = database.collection('message').doc(idMessage).set({
                id: id,
                sujet: sujet,
                texte: message,
                cible: morphologie,
                codePromo: codePromo,
                marque: isChecked ? marque : "",
                date: Date.now(),
            });
            res.then(() => {
                Swal.fire({
                    icon: "success",
                    title: "Le message a bien été modifié !",
                    text: "N'oubliez pas de le publier si vous en êtes satisfait(e) et que ce n'est pas déjà fait !"
                });

                show(false);
                setSujet("");
                setMessage("");
                setMorphologie("all");
                setCodePromo("");
                setMarque("")
                hasLoad(false);
                refresh(!r);
                loading(true);
            }).catch((e: any) => Swal.fire({
                icon: 'error',
                title: 'Oups...',
                text: 'Une erreur est survenue lors de la modification du message. Veuillez réessayer.'
            }))
        }
    }

    function changeSujet(value: any) {
        setSujet(value.target.value);
    }

    function changeMessage(event: any): any {
        setMessage(event.target.value)
    }

    function changeMorphologie(event: any): any {
        setMorphologie(event.target.value)
    }

    function changeCodePromo(event: any): any {
        setCodePromo(event.target.value)
    }

    function handleCheckboxChange(event: any): any {
        setIsChecked(event.target.checked);
        setInputDisabled(!event.target.checked);
    }

    function changeMarque(event: any): any {
        setMarque(event.target.value)
    }

    return (
        <Modal show={showIt}>
            <Modal.Header>
                <h1 className={styles.modalTitle}>Modifier un message</h1>
                <button className={styles.quitModal} onClick={hideModal}><FontAwesomeIcon icon={faXmark}/></button>
            </Modal.Header>

            <Modal.Body>
                <form className={`theme-form ${styles.messageModal}`}>
                    <label htmlFor="subject">* Sujet</label>
                    <input id="subject" name="subject" type="text" placeholder="Sujet du message" value={sujet}
                           onChange={changeSujet}/>

                    <label htmlFor="message">* Message</label>
                    <textarea id="message" name="message" placeholder="Contenu du message" value={message}
                              onChange={changeMessage}/>

                    <label htmlFor="morphologie">* Morphologie</label>
                    <select name="morphologie" id="morphologie" value={morphologie} onChange={changeMorphologie}>
                        <option value="all">Toutes</option>
                        <option value="a">A</option>
                        <option value="h">H</option>
                        <option value="o">O</option>
                        <option value="v">V</option>
                        <option value="x">X</option>
                        <option value="8">8</option>
                    </select>

                    <label htmlFor="codePromo">Code Promo</label>
                    <input type="text" name="text" id="text" value={codePromo} onChange={changeCodePromo}/>

                    <fieldset>
                        <legend>Sponsor</legend>
                        <label className={styles.sponso} htmlFor="sponso">
                            <input className={styles.sponso} type="checkbox" id="sponso" name="sponso" checked={isChecked} onChange={handleCheckboxChange}/>
                            &nbsp;Sponsorisé
                        </label>
                        <label className={inputDisabled ? styles.disabled : ""}>Marque</label>
                        <input className={styles.marque} type="text" onChange={changeMarque} value={marque}
                               disabled={inputDisabled}></input>
                    </fieldset>

                    <input type="button" id="btn-save" value="Envoyer" onClick={onModify}/>
                </form>
            </Modal.Body>
        </Modal>
    );

}  