import React from 'react';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import {observer} from 'mobx-react-lite';
import NavigationBar from './components/NavigationBar';
import AuthGuard from "./_helpers/AuthGuard";
import LoginGuard from "./_helpers/LoginGuard";
import {ContestsScreen, ConfigurationScreen, ErrorScreen, LoginScreen, MessageScreen, SignoutScreen} from "./screens";
import "./App.css"

const App = observer(() => {
    return (
        <BrowserRouter> {/* Utilisation de BrowserRouter au lieu de HashRouter */}
            <NavigationBar/>
            <Routes>
                <Route index element={<LoginGuard><LoginScreen/></LoginGuard>}/>

                <Route path="/concours" element={<AuthGuard><ContestsScreen/></AuthGuard>}/>
                <Route path="/messages" element={<AuthGuard><MessageScreen/></AuthGuard>}/>
                <Route path="/configuration" element={<AuthGuard><ConfigurationScreen/></AuthGuard>}/>
                <Route path={"signout"} element={<AuthGuard><SignoutScreen/></AuthGuard>}/>

                <Route path="*" element={<ErrorScreen/>}/>
            </Routes>
        </BrowserRouter>
    );
});

export default App;
