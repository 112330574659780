import React from 'react';
import {Input, TableCell} from "@mui/material";

type Props = {
    value: any,
    name: any,
    onChange: any
}

const CustomTableCell = ({value, name, onChange}: Props) => {
    return (
        <TableCell align="left">
            {value.isEditMode ? (
                <Input
                    value={value[name]}
                    name={name}
                    onChange={(e: any) => onChange(e, value)}
                />
            ) : (
                value[name]
            )}
        </TableCell>
    );
};

export default CustomTableCell;