import styles from "../styles/MessagesScreen.module.css";
import 'firebase/firestore';
import FireBaseRepository from "../repositories/FirebaseRepository";
import {useEffect, useState} from "react"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import AddMessageModal from "../components/messages/modals/AddMessageModal";
import MessageComponents from "../components/messages/MessageComponent";
import IMessage from "../models/interfaces/IMessage";
import Message from "../models/message";
import BeatLoader from "react-spinners/BeatLoader";

/**
 * Tableau qui contiendra les différents composents
 */
let messagesPreviews: JSX.Element[] = [];

export function MessageScreen() {

    const [showIt, setShowIt] = useState<boolean>(false);
    const [refresh, setRefresh] = useState(false);
    const [, setId] = useState("");
    const [loading, setLoading] = useState(true)
    const fbRepo = new FireBaseRepository();

    useEffect(() => {
        // Tableau remis à vide
        messagesPreviews = [];
        // Reload des messages en BD
        fbRepo.getMessage().then((value) => {
            value.forEach((data) => {
                let message: IMessage = new Message(data.id, data.date, data.sujet, data.texte, data.cible, data.codePromo, data.marque, data.estPublier);
                messagesPreviews.push(<MessageComponents message={message} id={setId} refresh={setRefresh} r={refresh}
                                                         loading={setLoading}/>);
            });
            // Quand les messages sont charger, on fait fin au loading
            setLoading(false);
        })
    }, [refresh])


    const onClickAdd = async () => {
        setShowIt(true);
    }

    if (loading) {
        return (
            <div className="beatLoader">
                <BeatLoader
                    color={'#e0576b'}
                    loading={loading}
                    size={30}
                />
            </div>)
    } else {
        return (
            <main className={styles.messagesWrapper}>
                <header>
                    <h1>Messages</h1>
                    <button className={styles.primaryButton} onClick={onClickAdd}>
                        <FontAwesomeIcon icon={faPlus}/><span className={styles.buttonText}>Rédiger un message</span></button>
                </header>
                <div className={styles.messages}>
                    {messagesPreviews.length === 0 ? <p>Aucun message à afficher.</p> : messagesPreviews}
                </div>
                <AddMessageModal showIt={showIt} show={setShowIt} refresh={setRefresh}
                                 r={refresh} loading={setLoading}/>
            </main>
        )
    }
}
