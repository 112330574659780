import React, {useEffect} from 'react'
import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from "@mui/material"
import EditIcon from "@material-ui/icons/EditOutlined";
import DoneIcon from "@material-ui/icons/DoneAllTwoTone";
import RevertIcon from "@material-ui/icons/NotInterestedOutlined";
import {Add, Delete} from '@material-ui/icons';
import styles from "../../styles/ClothesConfigurationComponent.module.css";
import FireBaseRepository from '../../repositories/FirebaseRepository';
import BeatLoader from "react-spinners/BeatLoader";
import CustomTableCell from "./CustomTableCell";

interface Props {
    pageTitle: string,
    configurationName: string,
    configurationData: any,
    configurationProperties: string[],
    configurationFiltersData: any,
    configurationFilters: string[],
    configurationDoubleFilters: any[]
}


const fbRepo = new FireBaseRepository();

export default function ClothesConfigurationComponents({
                                                           pageTitle,
                                                           configurationName,
                                                           configurationData,
                                                           configurationProperties,
                                                           configurationFiltersData,
                                                           configurationFilters,
                                                           configurationDoubleFilters,
                                                       }: Props) {

    const [loading, setLoading] = React.useState(true);
    const [filterOption, setFilterOption] = React.useState<string>('');
    const [doubleFilterOption, setDoubleFilterOption] = React.useState<string>('');
    const [doubleFilterOptions, setDoubleFilterOptions] = React.useState<string[]>([]);
    const [data, setData] = React.useState<any[]>([]);
    const [currentData, setCurrentData] = React.useState<any>({});

    useEffect(() => {
        setLoading(true);
        if (configurationFilters.length > 0) {
            filterData(configurationFilters[0]);
        } else {
            setData(configurationData);
            setFilterOption("");
            setDoubleFilterOption("");
            setDoubleFilterOptions([]);
        }
        noEditMode()
        setLoading(false);
    }, []);

    const noEditMode = () => {
        setData(old => old.map(row => {
            return {...row, isEditMode: false};
        }));
    }

    const filterData = (filter: string) => {
        setLoading(true);
        setFilterOption(filter);
        if (configurationDoubleFilters.length > 0) {
            let doubleFilters = JSON.parse(configurationFiltersData[filter]).sort();
            setDoubleFilterOptions(doubleFilters);
            setDoubleFilterOption(doubleFilters[0]);
            setData(configurationData[doubleFilters[0]].sort((a: { Type: string; }, b: { Type: string; }) => a.Type.localeCompare(b.Type)));
        }
        setLoading(false);
    }

    const doubleFilterData = (doubleFilter: any) => {
        setLoading(true);
        setDoubleFilterOption(doubleFilter);
        setData(configurationData[doubleFilter].sort((a: { Type: string; }, b: { Type: string; }) => a.Type.localeCompare(b.Type)));
        setLoading(false);
    }

    const onDataChange = async (e: { target: HTMLInputElement; }, row: any) => {
        setLoading(true);
        const value = e.target.value;
        const name = e.target.name;
        const targetRow = data.find(rowData => rowData.id === row.id);

        if (targetRow === undefined) {
            setLoading(false)
            return;
        }

        setData(data.map(rowData => {
            if (rowData.id !== row.id) return rowData
            return {...rowData, [name]: value};
        }));
        setLoading(false);
    };

    const onToggleEditMode = (id: number) => {
        setLoading(true);
        noEditMode()
        setData(old => old.map(row => {
            if (row.id === id) {
                setCurrentData(row);
                return {...row, isEditMode: true};
            } else {
                return {...row, isEditMode: false};
            }
        }));
        setLoading(false);
    }

    const onUntoggleEditMode = (id: number) => {
        setLoading(true);
        setCurrentData({});
        noEditMode()
        onSaveConfiguration()
        setLoading(false);
    }

    const onRevertData = () => {
        setLoading(true);
        setData(data.map(row => {
            if (row.id === currentData.id) {
                return currentData;
            } else {
                return row;
            }
        }));
        noEditMode()
        console.log(data)
        setLoading(false);
    }

    const onDeleteData = (id: number) => {
        setLoading(true);
        setData(data.filter(row => row.id !== id));
        setLoading(false);
    }

    const onAddData = () => {
        setLoading(true);
        if (Object.keys(currentData).length !== 0) {
            onRevertData();
        }

        let id = data.reduce((prev, current) => (prev.id > current.id) ? prev : current).id + 1

        let newData: { [k: string]: any } = {"id": id, "isEditMode": false};

        configurationProperties.forEach(value => value.substring(0, 5) === 'Score' ? newData[value] = 0 : newData[value] = "");

        data.unshift(newData);

        onToggleEditMode(id);
        setLoading(false);
    }

    const onSaveConfiguration = () => {
        setLoading(true);
        noEditMode();
        switch (configurationName) {

            case 'vêtements':
                let newData: { [k: string]: any } = {};
                Object.keys(configurationData).forEach((key) => {
                    newData[key] = key === doubleFilterOption ? data.sort((a, b) => a.id - b.id) : configurationData[key];
                });
                fbRepo.updateAlgorithmClothes(newData);
                data.sort((a: { Type: string; }, b: { Type: string; }) => a.Type.localeCompare(b.Type))
                break;

            case 'matériaux':
                fbRepo.updateAlgorithmMaterials(data.sort((a, b) => a.id - b.id));
                data.sort((a: { Name: string; }, b: { Name: string; }) => a.Name.localeCompare(b.Name))

                break;

            default :
                fbRepo.updateConfigurationData(configurationName, configurationName, data.sort((a, b) => a.id - b.id));
                data.sort((a: { nom: string; }, b: { nom: string; }) => a.nom.localeCompare(b.nom))
        }
        setLoading(false);
    }

    return (
        loading ?
            <div className={"beatLoader"}>
                <BeatLoader
                    color={'#e0576b'}
                    loading={loading}
                    size={30}
                />
            </div>
            :
            <div className={styles.clothesConfigurationPage}>

                <header className={styles.header}>
                    <div className={styles.pageTitleContainer}>
                        <h1 className={styles.pageTitle}>{pageTitle}</h1>
                    </div>

                    <div className={configurationFilters.length > 0 ? styles.pageOptions : styles.pageOptionsEmpty}>
                        {configurationFilters.map((filter, index) => {
                            return <p className={`${styles.pageOption} ${filter === filterOption ? styles.pageOptionSelected : ""}`}
                                      onClick={() => filterData(configurationFilters[index])}>{filter}</p>
                        })}
                    </div>

                    <div className={configurationDoubleFilters.length > 0 ? styles.pageOptions : styles.pageOptionsEmpty}>
                        {doubleFilterOptions.map((filter, index) => {
                            return <p className={`${styles.pageOption} ${filter === filterOption ? styles.pageOptionSelected : ""}`}
                                      onClick={() => doubleFilterData(doubleFilterOptions[index])}>{filter}</p>
                        })}
                    </div>
                </header>

                <div className={styles.pageTable}>
                    <TableContainer className={styles.algoTable} component={Paper}>
                        <Table stickyHeader className={styles.innerTable}>
                            <TableHead>
                                <TableRow>
                                    {configurationProperties.map((name, index) => {
                                        if (index === 0) return <TableCell
                                            className={styles.propertyTitle}>Morphologie</TableCell>
                                        return <TableCell
                                            className={styles.propertyTitle}>{name.substring(name.length - 1)}</TableCell>
                                    })}
                                    <TableCell><IconButton onClick={() => onAddData()}><Add/></IconButton></TableCell>
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                {data.map((value, index) => {
                                    return (<TableRow key={index}>
                                        {configurationProperties.map((name) => {
                                            return <CustomTableCell {...{value, name: name, onChange: onDataChange}} />
                                        })}
                                        <TableCell>
                                            {value.isEditMode ? (
                                                <>
                                                    <IconButton
                                                        onClick={() => onUntoggleEditMode(value["id"])}
                                                    >
                                                        <DoneIcon/>
                                                    </IconButton>
                                                    <IconButton
                                                        onClick={() => onRevertData()}
                                                    >
                                                        <RevertIcon/>
                                                    </IconButton>
                                                </>
                                            ) : (
                                                <IconButton onClick={() => onToggleEditMode(value["id"])}>
                                                    <EditIcon/>
                                                </IconButton>
                                            )}
                                        </TableCell>
                                        <TableCell><IconButton
                                            onClick={() => onDeleteData(value["id"])}><Delete/></IconButton></TableCell>
                                    </TableRow>)
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            </div>
    )
}