import IMessage from "./interfaces/IMessage";

/**
 * Classe qui modélise un message dans l'application.
 */
export default class Message implements IMessage {
    id!: string;
    date: string;
    sujet: string;
    texte: string;
    cible: string;
    codePromo: string;
    marque: string;
    estPublier: boolean

    constructor(
        id: string,
        date: string,
        sujet: string,
        texte: string,
        cible: string,
        codePromo: string,
        marque: string,
        estPublier: boolean
    ) {
        this.id = id;
        this.date = date;
        this.sujet = sujet;
        this.texte = texte;
        this.cible = cible;
        this.codePromo = codePromo;
        this.marque = marque;
        this.estPublier = estPublier
    }

}
