import IContest from "./interfaces/IContest";

/**
 * Classe qui modélise un concours dans l'application.
 */
export default class Contest implements IContest {
    id!: string;
    dateDebut: string;
    dateFin: string;
    estPublier: boolean;
    lienConcours: string;
    theme: string;
    description: string;

    constructor(
        id: string,
        dateDebut: string,
        dateFin: string,
        estPublier: boolean,
        lienConcours: string,
        theme: string,
        description: string,
    ) {
        this.id = id;
        this.dateDebut = dateDebut;
        this.dateFin = dateFin;
        this.estPublier = estPublier;
        this.lienConcours = lienConcours;
        this.theme = theme;
        this.description = description;
    }

}
