import styles from '../styles/ErrorScreen.module.css'

const ErrorScreen = () => {
    return (
        <main className={styles.notFoundError}>
            <h1 className={styles.errorTitle}>Erreur 404</h1>
            <p className={styles.errorDesc}>La page demandée n'a pas été trouvée</p>
        </main>
    );
};

export default ErrorScreen;