import Modal from "react-bootstrap/Modal";
import "bootstrap/dist/css/bootstrap.min.css";
import {useState} from "react";
import {auth, database} from "../../../firebase";
import uuid from 'react-native-uuid';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faXmark} from "@fortawesome/free-solid-svg-icons";
import Swal from "sweetalert2";
import styles from "../../../styles/MessagesScreen.module.css"

interface Props {
    show: Function;
    showIt: boolean;
    refresh: Function;
    r: Boolean;
    loading: Function;
}

export default function AddMessageModal({showIt, show, refresh, r, loading}: Props) {

    const [sujet, setSujet] = useState("");
    const [message, setMessage] = useState("");
    const [morphologie, setMorphologie] = useState("all");
    const [codePromo, setCodePromo] = useState("");
    const [marque, setMarque] = useState("");
    const [isChecked, setIsChecked] = useState(false);
    const [inputDisabled, setInputDisabled] = useState(true);

    function changeSujet(event: any): any {
        setSujet(event.target.value)
    }

    function changeMessage(event: any): any {
        setMessage(event.target.value)
    }

    function changeMorphologie(event: any): any {
        setMorphologie(event.target.value)
    }

    function changeCodePromo(event: any): any {
        setCodePromo(event.target.value)
    }

    function changeMarque(event: any): any {
        setMarque(event.target.value)
    }

    function handleCheckboxChange(event: any): any {
        setIsChecked(event.target.checked);
        setInputDisabled(!event.target.checked);
    }


    const onPublish = async () => {

        if (!auth.currentUser) {
            Swal.fire({
                title: "Vous n'êtes pas authentifié.",
                text: "Veuillez vous connecter afin de pouvoir utiliser l'interface d'administration."
            })
            return;
        }

        let error: string = "";

        if (sujet.trim().length === 0) {
            error += "<li>Le sujet du message ne peut pas être vide.</li>";
        }

        if (sujet.trim().length > 50) {
            error += "<li>Le sujet du message ne peut excéder les 50 caractères.</li>";
        }

        if (message.trim().length === 0) {
            error += "<li>Le message ne peut pas être vide.</li>";
        }

        if (message.length >= 500) {
            error += "<li>Le message ne peut excéder les 500 caractères.</li>";
        }

        if (marque.length >= 50 && isChecked) {
            error += "<li>Le champ 'Marque' ne peut excéder les 50 caractères.</li>";
        }

        if (marque.trim().length === 0 && isChecked) {
            error += "<li>Le champ 'Marque' ne peut pas être vide</li>";
        }

        if (error.length > 0) {
            error = "<ul style='text-align: left'>" + error + "</ul>";

            Swal.fire({
                icon: 'error',
                title: 'Impossible de créer le message',
                html: error
            })
        } else {
            var id = uuid.v4().toString();
            console.log(JSON.stringify({
                id: id,
                sujet: sujet,
                texte: message,
                cible: morphologie,
                codePromo: codePromo,
                marque: marque && isChecked ? marque : "",
                estPublier: false,
                date: Date.now()
            }))
            const res = database.collection('message').doc(id).set({
                id: id,
                sujet: sujet,
                texte: message,
                cible: morphologie,
                codePromo: codePromo,
                marque: marque && isChecked ? marque : "",
                estPublier: false,
                date: Date.now()
            });
            res.then(() => {

                Swal.fire({
                    icon: "success",
                    title: "Le message a bien été créé !",
                    text: "N'oubliez pas de le publier quand vous en êtes satisfait(e) !"
                });

                show(false);
                setSujet("");
                setMessage("");
                setMorphologie("all");
                setCodePromo("");
                setMarque("");
                refresh(!r);
                loading(true);

            }).catch((e: any) => Swal.fire({
                icon: 'error',
                title: 'Une erreur est survenue',
                text: 'Une erreur est survenue lors de la création du message. Veuillez réessayer.'
            }))
        }
    }


    const hideModal = () => {
        show(false);
    };

    return (
        <Modal show={showIt}>
            <Modal.Header>
                <h1 className={styles.modalTitle}>Rédiger un message</h1>
                <button className={styles.quitModal} onClick={hideModal}><FontAwesomeIcon icon={faXmark}/></button>
            </Modal.Header>

            <Modal.Body>
                <form className={`theme-form ${styles.messageModal}`}>
                    <label htmlFor="subject">* Sujet</label>
                    <input data-testid="subjectInput" id="subject" name="subject" type="text"
                           placeholder="Sujet du message" value={sujet} onChange={changeSujet}/>

                    <label htmlFor="message">* Message</label>
                    <textarea data-testid="messageInput" id="message" name="message" placeholder="Contenu du message"
                              value={message} onChange={changeMessage}/>

                    <label htmlFor="morphologie"> Morphologie</label>
                    <select data-testid="morphInput" name="morphologie" id="morphologie" value={morphologie}
                            onChange={changeMorphologie}>
                        <option value="all">Toutes</option>
                        <option value="a">A</option>
                        <option value="h">H</option>
                        <option value="o">O</option>
                        <option value="v">V</option>
                        <option value="x">X</option>
                        <option value="8">8</option>
                    </select>
                    <label htmlFor="codePromo">Code Promo</label>
                    <input data-testid="promoInput" type="text" name="codePromo" id="codePromo" value={codePromo}
                           onChange={changeCodePromo}/>

                    <fieldset>
                        <legend>Sponsor</legend>
                        <label className={styles.sponso} htmlFor="sponso"><input className={styles.sponso} type="checkbox" id="sponso"
                                                                          name="sponso" checked={isChecked}
                                                                          onChange={handleCheckboxChange}/>&nbsp;Sponsorisé</label>
                        <label className={inputDisabled ? styles.disabled : ""}>Marque</label>
                        <input className={styles.marque} type="text" onChange={changeMarque} value={marque}
                               disabled={inputDisabled}></input>
                    </fieldset>

                    <input type="button" id="btn-save" value="Envoyer" data-testid="submitMessageButton"
                           onClick={onPublish}/>
                </form>
            </Modal.Body>
        </Modal>
    );

}  