import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import {useEffect, useState} from "react"
import styles from "../styles/ContestsScreen.module.css"
import AddContestModal from "../components/contests/modals/AddContestModal";
import FirebaseRepository from "../repositories/FirebaseRepository";
import Contest from "../models/contest";
import ContestComponent from "../components/contests/ContestComponent";
import BeatLoader from "react-spinners/BeatLoader";
import dayjs from "dayjs";

let concours: Contest[] = [];

export function ContestsScreen() {

    const [showIt, setShowIt] = useState<boolean>(false);
    const [refresh, setRefresh] = useState(false);
    const [loading, setLoading] = useState(true)
    const [filter, setFilter] = useState("all");
    const fbRepo = new FirebaseRepository();

    function changeFilter(event: any): any {
        setFilter(event.target.value)
    }

    useEffect(() => {
        concours = [];
        fbRepo.getConcours().then((value) => {
            value.forEach((data) => concours.push(new Contest(data.id, data.dateDebut, data.dateFin, data.estPublier, data.lienConcours, data.theme, data.description)));
            setLoading(false);
        });
    }, [refresh])


    const onClickAdd = async () => {
        setShowIt(true);
    }

    const toDisplay = filter === "draft" ? concours.filter(concours => !concours.estPublier).map(concours =>
            <ContestComponent concours={concours} refresh={setRefresh} r={refresh} loading={setLoading}/>) :
        filter === "active" ? concours.filter(concours => concours.estPublier && dayjs(concours.dateDebut).isBefore(dayjs()) && dayjs(concours.dateFin).isAfter(dayjs())).map(concours =>
                <ContestComponent concours={concours} refresh={setRefresh} r={refresh} loading={setLoading}/>) :
            filter === "finished" ? concours.filter(concours => concours.estPublier && dayjs(concours.dateFin).isBefore(dayjs())).map(concours =>
                    <ContestComponent concours={concours} refresh={setRefresh} r={refresh} loading={setLoading}/>) :
                concours.map(concours => <ContestComponent concours={concours} refresh={setRefresh} r={refresh} loading={setLoading}/>);


    if (loading) {
        return (
            <div className="beatLoader">
                <BeatLoader
                    color={'#e0576b'}
                    loading={loading}
                    size={30}
                />
            </div>)
    } else {
        return (
            <main className={styles.contestsWrapper}>
                <header className={styles.header}>
                    <h1>Concours</h1>
                    <select id="type-concours" onChange={changeFilter} className={styles.select}>
                        <option value="all">Tous les concours</option>
                        <option value="active">En cours</option>
                        <option value="finished">Terminé</option>
                        <option value="draft">Brouillon</option>
                    </select>
                    <button className={styles.primaryButton} onClick={onClickAdd}><FontAwesomeIcon className={styles.buttonIcon} icon={faPlus}/><span
                        className={styles.buttonText}>Créer un concours</span></button>
                </header>
                <div className={styles.contests}>
                    {toDisplay.length === 0 ? <p>Aucun concours à afficher.</p> : toDisplay}
                </div>
                <AddContestModal showIt={showIt} show={setShowIt} refresh={setRefresh} r={refresh}
                                 loading={setLoading}/>
            </main>
        )
    }
}  